import * as actionTypes from './setUserMenuAccessActionTypes';

export const setUserMenuAccess = (data) => {
    
    return {
        type: actionTypes.SET_USER_MENU_DETAILS,
        setMenuData: data.setMenuData
    }
}

export const removeUserMenuAccess = (data) => {
    sessionStorage.removeItem('setMenuData');
    return {
        type: actionTypes.REMOVE_USER_MENU_DETAILS,
        //setMenuData: data
    }
}