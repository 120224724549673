import * as actionTypes from './loginActionTypes';

export const loginUser = (data) => {
   
    return {
        type: actionTypes.ADD_LOGIN_DETAILS,
        //userData: data
        userData: data
    }
}

export const logoutUser = (data) => {
    
    return {
        type: actionTypes.REMOVE_LOGIN_DETAILS,
        //userData: data
    }
}