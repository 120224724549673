import { STATE_LOGIN } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import PrivateRoute from './components/PrivateRoute';
import { createBrowserHistory } from "history";
import AuthPage from './pages/AuthPage';
import React from 'react';
import { BrowserRouter,  Redirect, Switch, Route } from 'react-router-dom';
import { checkToken, setUserSession, removeUserSession } from './utils/Common';
import ResetPasswordPage from './pages/ResetPasswordPage';

//import Axios from 'axios';
import { connect } from 'react-redux';
import {loginUser} from './store/actions/login';
import {logoutUser} from './store/actions/login';

import './styles/styles.scss';

const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));
const AdminPage = React.lazy(() => import('./pages/AdminPage'));
const Logout = React.lazy(() => import('./pages/LogoutPage'));
const UserPage = React.lazy(() => import('./pages/user/UserPage'));


const history = createBrowserHistory();

class App extends React.Component {
  
  // async componentDidMount() {
  //   if( sessionStorage.getItem('isLogin') === 'true' && sessionStorage.getItem('token_id') !== '' ) {
  //     await this.getUserData();
  //   } else {
     
  //     await this.props.logoutUserHandle();
  //     await this.setState( { isLogin: true } );
  //   }
  // }

  render() { 
    
    const isLogin = checkToken();
    
    if ( ( isLogin ) && history.location.pathname !== '/signout' ) {
      if( isLogin ) {
        // alert(1);
        //let formData = new FormData();
        //formData.append('id', sessionStorage.getItem('token_id') );
        // Axios.defaults.headers.post['TokenID'] = sessionStorage.getItem('token_id');
        // Axios.get('users/622f1633dd6861f5692cddd1', formData)
        // .then( response => { 
          
        //   if( response.data.StatusCode === '1' ){
          
            //const data = response.data.Result;

            let token_id = sessionStorage.getItem('token_id');
            let user_id = sessionStorage.getItem('user_id');
            let user_email =sessionStorage.getItem('useremail');
            let first_name =sessionStorage.getItem('first_name');
            let last_name =sessionStorage.getItem('last_name');
            let role_id =sessionStorage.getItem('role_id');
            let role =sessionStorage.getItem('role');

            let isDisplayPricePerm =sessionStorage.getItem('isDisplayPricePerm');
            let isDisplayStatusPerm =sessionStorage.getItem('isDisplayStatusPerm');
            let isEditOrderPerm =sessionStorage.getItem('isEditOrderPerm');
            

            let rememberMe = localStorage.getItem('rememberMe');
            
            /*
            setUserSession(
              token_id,
              user_id,
              Result.first_name,
              Result.last_name,
              Result.email,
              Result.role,
              rememberMe,
            ); */

            this.props.loginUserHandle({
              user_id: user_id,
              // username: Result.user_username,
              email: user_email,
              role_id: role_id,
              role: role,
              isDisplayPricePerm,
              isDisplayStatusPerm,
              isEditOrderPerm,
              // designation: Result.designation,
              // user_profile: Result.user_profile,
            });
        //   } else if ( response.data.StatusCode === '10' ) {
        //     removeUserSession();
        //     this.props.logoutUserHandle();
        //   }
        // });
      } else {
        // alert(2);
        removeUserSession();
        this.props.logoutUserHandle();
      }
    }
    
      return (
        <BrowserRouter >
        {/* <BrowserRouter basename={'/backendurl/'} > */}
          <GAListener>
            <React.Suspense fallback={<PageSpinner />}>
              <Switch>
                  <LayoutRoute
                    exact
                    path="/"
                    layout={EmptyLayout}
                    component={props => (
                      <AuthPage {...props} authState={STATE_LOGIN} /> 
                    )}
                  />

                  {/* http://localhost:3001/reset-password?token=NjE5YjAxN2Y4ZDg1ZDM2ZWEzNWM5MDkxYThiMjZhMDEyMmRlNGEwNzc4MDU5MmQ5NzlhYTQ4YjU5MWYxMTc0Mg== */}
                  
                  <LayoutRoute
                    exact
                    path="/reset-password"
                    layout={EmptyLayout}
                    component={props => (
                      <ResetPasswordPage {...props} /> 
                    )}
                  />
                  <PrivateRoute exact path="/admin/*" component={AdminPage}  />
                  <PrivateRoute exact path="/signout" component={Logout} />
                  {/* <PrivateRoute exact path="/user/*" component={UserPage}  /> */}
                  
                  <Route path='/not-found' > 
                    <PageNotFound /> 
                  </Route>
                  <Redirect from="*" to="/not-found" />
              </Switch>
            </React.Suspense> 
          </GAListener>
        </BrowserRouter>
      );
     
  }
}



const mapDispatchToProps = (dispatch) => ({ 
  loginUserHandle:data=>dispatch(loginUser(data)),
  logoutUserHandle:data=>dispatch(logoutUser(data)),
});


export default connect(null, mapDispatchToProps)(App)


