import Avatar from '../../components/Avatar';
import { UserCard } from '../../components/Card';
//import SearchInput from 'components/SearchInput';
import { NavLink as NavCustom } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';

import {
  MdClearAll,
  MdExitToApp,
  MdPersonPin,
  MdSettingsApplications
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const bem = bn.create('header');

class Header extends React.Component {
  
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };
  
  // componentDidMount () {
    
  //   document.body.addEventListener('click', (flag) => this.toggleUserCardPopover(false));
  // }
  // UNSAFE_componentWillUnmount () {
  //   document.body.removeEventListener('click', this.toggleUserCardPopover);
  // }

  // toggleNotificationPopover = () => {
  //   this.setState({
  //     isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
  //   });

  //   if (!this.state.isNotificationConfirmed) {
  //     this.setState({ isNotificationConfirmed: true });
  //   }
  // };

  // toggleUserCardPopover = (flag) => {
    
  //   if( flag ) {
      
  //     this.setState({
  //       isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
  //     });
  //   } else {
  //     this.setState({
  //       isOpenUserCardPopover: false,
  //     });
  //   }
  // };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };
 
  render() {

    let ProfileUrl = "/user/profile";
    let Settings = null;
    if(  sessionStorage.getItem('role_id') !== null ) {
      
      if( sessionStorage.getItem('role_id') === "1" ) {
        ProfileUrl = "/admin/profile";
        Settings = <NavCustom to="/admin/settings" >
                      <ListGroupItem tag="button" action className="border-light" >
                        <MdSettingsApplications /> 
                        <span className="pl-3">Settings</span>
                      </ListGroupItem>
                    </NavCustom>
      } 
    } 
    
    return (<>

           <Button className="menu-toggler" outline onClick={this.handleSidebarControlButton}>
             <MdClearAll size={25} />
           </Button>
    </>
      // <Navbar light expand className={bem.b('bg-white')}>
      //   <Nav navbar className="mr-2">
      //     <Button outline onClick={this.handleSidebarControlButton}>
      //       <MdClearAll size={25} />
      //     </Button>
      //   </Nav>
      //   <Nav navbar>
      //     {/* <SearchInput /> */}
      //   </Nav>

      //   <Nav navbar className={bem.e('nav-right')}>

      //     <NavItem>
      //       <NavLink id="Popover2">
      //         <Avatar
      //           onClick={(flag) => this.toggleUserCardPopover(true)}
      //           className="can-click"
      //         />
      //       </NavLink>
      //       <Popover
      //         placement="bottom-end"
      //         isOpen={this.state.isOpenUserCardPopover}
      //         toggle={(flag) => this.toggleUserCardPopover(true)}
      //         target="Popover2"
      //         className="p-0 border-0"
      //         style={{ minWidth: 250 }}
      //       >
      //         <PopoverBody className="p-0 border-light">
      //           <UserCard
      //             title={sessionStorage.getItem('username')}
      //             subtitle={(this.props.userData) ? this.props.userData.designation : "" }
      //             className="border-light text-capitalize"
      //           >
      //           </UserCard>
      //             <ListGroup flush>
      //               <NavCustom to={ProfileUrl} >

      //                 <ListGroupItem tag="button" action className="border-light">
      //                   <MdPersonPin /> 
      //                   <span className="pl-3">Profile</span>
      //                 </ListGroupItem>
      //               </NavCustom>
      //               {Settings}
      //               <NavCustom to="/signout" >
      //                 <ListGroupItem tag="button" action className="border-light">
      //                   <MdExitToApp /> 
      //                   <span className="pl-3">Signout</span>
      //                 </ListGroupItem>
      //               </NavCustom>
      //             </ListGroup>
      //         </PopoverBody>
      //       </Popover>
      //     </NavItem>
      //   </Nav>
      // </Navbar>
    );
  }
}
const mapStateToProps = state => ({
  userData : state.login
});
//export default Header;
export default connect(mapStateToProps, null)(Header)
