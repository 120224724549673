import * as actionTypes from '../actions/setUserMenuAccessActionTypes';

const initialState = {
    setMenuData : [],
};
const reducer = ( state = initialState, action ) => {
    
    switch ( action.type ) {
        case actionTypes.SET_USER_MENU_DETAILS:
            
            return{
                ...state,
                // menuData: action.menuData
                setMenuData : [...action.setMenuData],
            };
            
        case actionTypes.REMOVE_USER_MENU_DETAILS:
            return{
                // ...this.state,
                setMenuData : [],
            };
        default :    
            return state;    
    }
};
export default reducer;