import { combineReducers } from 'redux';
import login from './login';
import menuAccess from './menuAccess';
import setUserMenuAccess from './setUserMenuAccess';

export default combineReducers({
  login,
  menuAccess,
  setUserMenuAccess,
});
