import logo200Image from '../../assets/img/logo/favicon.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from '../../components/SourceLink';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { removeUserSession } from '../../utils/Common';

import Axios from 'axios';

import {logoutUser} from '../../store/actions/login';
import {menuAccess} from '../../store/actions/menuAccess';
import {setUserMenuAccess} from '../../store/actions/setUserMenuAccess';

import {
  MdDashboard,
  MdPeople,
  MdEventNote,
  MdEmail,
  MdSms,
  MdInventory,
  MdExitToApp,
  MdGroup,
  // MdImportContacts
} from 'react-icons/md';

import {

  FaBox,
  FaRegHospital

} from 'react-icons/fa';

import { NavLink } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    menuData: [],
  };
  
  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  componentWillMount() {
    
    // console.log("this.props=========================");
    // console.log(this.props.location.pathname.startsWith("/admin/orders/claims"))
    
    if( sessionStorage.getItem('token_id') !== null ) {

      let formData = new FormData();
      
      Axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token_id');
      Axios.get('menu-list/new', formData)
      .then( response => { 

        // console.log("menu-list/new response");
        // console.log(response);
        
        if( response.data.StatusCode === '0' ){
          this.setState( { menuData: [] } );
        } else if( response.data.StatusCode === '10' ) {
          removeUserSession();
          this.props.logoutUserHandle();
          this.props.history.push('/');
        } else {
          const data = response.data.Result[0].data;
          
          this.setState( { menuData: data } );
          this.props.menuAccessHandle( { 'menuData' : data } );
          
          const currentUrl = window.location.pathname.replace('/uss_hr', '');
          // menu permission set
          const setMenuData = this.props.menuData.filter(function(menu) {
            
            return menu.menu_page === currentUrl
          });
          
          this.props.setUserMenuAccessHandle( { 'setMenuData' : setMenuData } );
        } 
      }).catch( error => {
        this.setState( { menuData: [] } );
      });
    } 
  }
  
  getIcom(name){
    switch (name) {
      case "MdDashboard":
        return MdDashboard;
        break;
      case "FaBox":
        return FaBox;
        break;
      case "MdEventNote":
        return MdEventNote;
        break;
      case "MdPeople":
        return MdPeople;
        break;
      case "MdEmail":
        return MdEmail;
        break;
      case "MdSms":
        return MdSms;
        break;
      case "FaRegHospital":
        return FaRegHospital;
        break;
      case "MdGroup":
        return MdGroup;
        break;
        
      default:
        return MdDashboard
        break;
    }
   
  }
  
  addMenuAccessHandle = (menu_page) => {
    const setMenuData = this.props.menuData.filter(function(menu) {
      return menu.menu_page === menu_page
    });
    
    this.props.setUserMenuAccessHandle( { 'setMenuData' : setMenuData } );
  }

  render() {

    const navItems =  [];
    
    this.state.menuData.length > 0
    && this.state.menuData.map((menu) => {
      
      //if( menu.menu_access_read === "1" ) {
        
        return (
            navItems.push(
              { menu_id: menu.menu_id, to: menu.menu_page, name: menu.menu_name, exact: true, Icon: this.getIcom(menu.nemu_icon) }
            )
        )
      // } else {
      //   return null
      // }
    },this);
    
    return (
      <aside className={bem.b() + " test-ab cr-sidebar--open"} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex" target="_SELF" >
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              {/* <span style={{color: 'initial'}}>
                RxFree4Me 
              </span> */}
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ menu_id, to, name, exact, Icon }, index) =>(
              
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  active={ this.props?.location?.pathname.startsWith("/admin/orders/claims") && to === '/admin/orders' }
                  activeClassName="active" 
                  exact={exact} onClick={()=>this.addMenuAccessHandle(to)}
                >
                  
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name} </span>
                </BSNavLink>
              </NavItem>
            ) )}

            <NavItem key={"6"} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${"Signout"}-${"6"}`}
                className="text-uppercase"
                tag={NavLink}
                to={"/admin/import-refill"}
                active={ this.props?.location?.pathname.startsWith("/admin/import-refill") }
                activeClassName="active" 
                exact
              >
                <FaBox className={bem.e('nav-item-icon')} />
                <span className="">{"Import Refill"} </span>
              </BSNavLink>
            </NavItem>
            
            <NavItem key={"6"} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${"Signout"}-${"6"}`}
                className="text-uppercase"
                tag={NavLink}
                to={"/signout"}
                activeClassName="active" 
                exact onClick={()=>this.addMenuAccessHandle("/signout")}
              >
                <MdExitToApp className={bem.e('nav-item-icon')} />
                <span className="">{"Sign out"} </span>
              </BSNavLink>
            </NavItem>

           
          </Nav>
          {/* <Nav>
              <NavItem key={"6"} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${"Signout"}-${"6"}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={"/signout"}
                  activeClassName="active" 
                  exact onClick={()=>this.addMenuAccessHandle("/signout")}
                >
                  <MdExitToApp className={bem.e('nav-item-icon')} />
                  <span className="">{"Sign out"} </span>
                </BSNavLink>
              </NavItem>
          </Nav> */}
        </div>
      </aside>
    );
  }
}
const mapStateToProps = state => {
  // console.log("redux state")
  // console.log(state)
  return{menuData : state.menuAccess.menuData}
};
const mapDispatchToProps = (dispatch) => ({ 
  logoutUserHandle:data=>dispatch(logoutUser(data)),
  menuAccessHandle:data=>dispatch(menuAccess(data)),
  setUserMenuAccessHandle:data=>dispatch(setUserMenuAccess(data))
});
//export default Sidebar;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
