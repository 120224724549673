import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import App from './App';
import Axios from 'axios';

// import reducer from './store/reducers/login';
import reducer from './store/reducers';
// import * as actionTypes from '../src/store/actions/loginActionTypes';
import { logoutUser } from '../src/store/actions/login';
import { removeUserSession } from '../src/utils/Common';


const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

// Live
Axios.defaults.baseURL = 'https://www.rxfree4me.com:3001/'; /* server live port */
// Local
// Axios.defaults.baseURL = 'http://localhost:3010/';


Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Axios.defaults.headers.post['Accept'] = 'application/json';


const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

const {dispatch} = store;
Axios.interceptors.response.use(
  response => response,
  error => {
    console.log("axios.interceptors error>>>",error)
    // console.log("axios.interceptors error.response>>>",error.response)

    if(error?.response?.status === 401 &&
    error?.response?.statusText === "Unauthorized"){
      
      removeUserSession();
      dispatch(logoutUser());
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(app, document.getElementById('root'));
