import * as actionTypes from '../actions/menuAccessActionTypes';

const initialState = {
    menuData : [],
};
const reducer = ( state = initialState, action ) => {
    
    switch ( action.type ) {
        case actionTypes.ADD_MENU_DETAILS:
            
            return{
                ...state,
                // menuData: action.menuData
                menuData : [...action.menuData],
            };
            
        case actionTypes.REMOVE_MENU_DETAILS:
            return{
                // ...this.state,
                menuData : [],
            };
        default :    
            return state;    
    }
};
export default reducer;