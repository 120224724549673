import * as actionTypes from './menuAccessActionTypes';

export const menuAccess = (data) => {
    
    return {
        type: actionTypes.ADD_MENU_DETAILS,
        menuData: data.menuData
    }
}

export const removeMenuAccess = (data) => {
    sessionStorage.removeItem('menuData');
    return {
        type: actionTypes.REMOVE_MENU_DETAILS,
        //menuData: data
    }
}