import * as actionTypes from '../actions/loginActionTypes';

const initialState = {
    user_id : null,
    role_id : null,
    role : null,
    token_id:null,
    username:null,
    email:null,
    user_profile:null,
    isLogin:false,
};
const reducer = ( state = initialState, action ) => {
    // console.log( "reducer action.type", action.type )
    switch ( action.type ) {
        case actionTypes.ADD_LOGIN_DETAILS:
            // console.log( "reducer ADD_LOGIN_DETAILS action.userData", action.userData )
            
            return{
                ...state,
                // userData: action.userData
                ...action.userData,
                isLogin: true
            };
        case actionTypes.REMOVE_LOGIN_DETAILS:
            return{
                // ...this.state,
                role_id : null,
                token_id:null,
                username:null,
                email:null,
                user_profile:null,
                isLogin:false,
            };
        default :    
            return state;    
    }
};
export default reducer;