import React from 'react';
import PropTypes from '../utils/propTypes';

import { useSelector } from 'react-redux'

import classNames from 'classnames';

import userImage from '../assets/img/users/user.png';

var tempImage = userImage;

const Avatar = ({
    rounded,
    circle,
    src,
    size,
    tag: Tag,
    className,
    style,
    ...restProps
}) => {

    const classes = classNames({ 'rounded-circle': circle, rounded }, className);

    const userData = useSelector(state => state.login);
    if ( userData.user_profile === null || userData.user_profile === "" ) {
        
        tempImage = userImage;
    } else {
        
        tempImage = "https://ussdevelopment.com/api/uss_hr/uploads/users/"+userData.user_profile;
    }
    
    return (
        <Tag
            src={tempImage}
            style={{ width: size, height: size, ...style }}
            className={classes}
            {...restProps}
        />
    );
};

Avatar.propTypes = {
    tag: PropTypes.component,
    rounded: PropTypes.bool,
    circle: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    src: PropTypes.string,
    style: PropTypes.object,
};

Avatar.defaultProps = {
    tag: 'img',
    rounded: false,
    circle: true,
    size: 40,
    src: tempImage,
    style: {},
};


export default Avatar;